import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Input,
  Link,
  Modal,
  ModalDialog,
  Grid,
  Typography,
  Stack,
  FormLabel,
  FormControl,
  Card,
  Box,
  Divider,
  Alert
} from '@mui/joy';
import Lottie from 'lottie-react';
import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword
} from 'firebase/auth';

import { useAuthAndBirthday } from '../context/AuthAndBirthdayContext';
import { getAnalytics, logEvent } from 'firebase/analytics';
import loader from '../media/balloons.json';

import backgroundImage from '../media/birthdays-candles.png';
import { doc, getFirestore, setDoc } from 'firebase/firestore';

export default function Login() {
  const { register, handleSubmit } = useForm();
  const {
    register: newAccountRegister,
    handleSubmit: newAccountHandleSubmit,
    getValues: newAccountGetValues
  } = useForm();
  const {
    register: forgotPasswordRegister,
    handleSubmit: forgotPasswordHandleSubmit,
    getValues: forgotPasswordGetValues
  } = useForm();
  const { loading } = useAuthAndBirthday();
  const [loginError, setLoginError] = useState('');
  const [dialog, setDialog] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const navigate = useNavigate();

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Lottie animationData={loader} />
      </Box>
    );
  }

  const onLoginSubmit = async loginData => {
    setLoggingIn(true);
    try {
      await signInWithEmailAndPassword(
        getAuth(),
        loginData.email,
        loginData.password
      );

      console.log('logged in ok');

      logEvent(getAnalytics(), 'login', { email: loginData.email });
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setLoggingIn(false);
      switch (error.code) {
        case 'auth/user-not-found':
          setLoginError(
            "You don't appear to be registered with us. Need an account?"
          );
          break;
        case 'auth/wrong-password':
          setLoginError("You've entered an incorrect password 😜");
          break;
        default:
          setLoginError(error.message);
      }
    }
  };

  const onNewAccountSubmit = async () => {
    try {
      const { name, email, password } = newAccountGetValues();

      const userCredential = await createUserWithEmailAndPassword(
        getAuth(),
        email,
        password
      );

      await setDoc(doc(getFirestore(), 'users', userCredential.user.uid), {
        name,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        birthdayReminders: {
          emails: true,
          notifications: false
        }
      });

      logEvent(getAnalytics(), 'sign_up', {
        email
      });
      setDialog(
        <>
          <Typography component="h2">Success!</Typography>
          <Typography textColor="text.tertiary">
            Your new account is ready for you {name}.
          </Typography>
        </>
      );
    } catch (error) {
      setDialog(
        <>
          <Typography component="h2">Error creating account</Typography>
          <Typography textColor="text.tertiary">error.message</Typography>
          <Typography textColor="text.tertiary">
            No account was created. Please try again.
          </Typography>
        </>
      );
    }
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          height: '100vh'
        }}
      >
        <Grid>
          <Card
            variant="outlined"
            sx={{
              padding: 3
            }}
          >
            <Stack spacing={2}>
              <Typography level="h1">Birthdays AI</Typography>
              <Typography level="body-lg" textColor="text.secondary">
                Keeping track of birthdays for you.
              </Typography>
              <form onSubmit={handleSubmit(onLoginSubmit)}>
                <Stack spacing={3}>
                  {loginError && (
                    <Alert color="danger">
                      <Typography color="danger" variant="soft">
                        {loginError}
                      </Typography>
                    </Alert>
                  )}
                  <FormControl>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      required
                      label="Email Address"
                      autoComplete="email"
                      autoFocus
                      size="lg"
                      {...register('email')}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                      required
                      type="password"
                      {...register('password')}
                      size="lg"
                    />
                  </FormControl>
                  <Button type="submit" loading={loggingIn}>
                    Sign In
                  </Button>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Link
                      onClick={() =>
                        setDialog(
                          <Stack spacing={2}>
                            <Typography component="h2">
                              Password Reset
                            </Typography>
                            <Typography textColor="text.tertiary">
                              Forgot your password or want to change it? Enter
                              your email below, and if you have an account with
                              us, we'll send you an email to help you out.
                            </Typography>
                            <form
                              onSubmit={forgotPasswordHandleSubmit(async () => {
                                setDialog(null);
                                const { password } = forgotPasswordGetValues();
                                sendPasswordResetEmail(
                                  getAuth(),
                                  password
                                ).catch(error => console.error(error));
                              })}
                            >
                              <Stack spacing={2}>
                                <FormControl>
                                  <FormLabel>Email address</FormLabel>
                                  <Input
                                    autoFocus
                                    required
                                    size="lg"
                                    fullWidth
                                    {...forgotPasswordRegister('password')}
                                  />
                                </FormControl>
                                <Button type="submit">Reset password</Button>
                              </Stack>
                            </form>
                          </Stack>
                        )
                      }
                      level="body2"
                    >
                      Reset Password
                    </Link>
                    <Link
                      level="body2"
                      onClick={() =>
                        setDialog(
                          <Stack spacing={2}>
                            <Typography component="h2">
                              Create an account
                            </Typography>
                            <Typography textColor="text.tertiary">
                              We're excited to have you join us!
                            </Typography>
                            <form
                              onSubmit={newAccountHandleSubmit(
                                onNewAccountSubmit
                              )}
                            >
                              <Stack spacing={2}>
                                <FormControl>
                                  <FormLabel>Name</FormLabel>
                                  <Input
                                    autoFocus
                                    size="lg"
                                    {...newAccountRegister('name')}
                                  />
                                </FormControl>
                                <FormControl>
                                  <FormLabel>Email address</FormLabel>
                                  <Input
                                    type="email"
                                    size="lg"
                                    {...newAccountRegister('email')}
                                  />
                                </FormControl>
                                <FormControl>
                                  <FormLabel>Password</FormLabel>
                                  <Input
                                    type="password"
                                    size="lg"
                                    {...newAccountRegister('password')}
                                  />
                                </FormControl>
                                <Button type="submit">Register</Button>
                              </Stack>
                            </form>
                          </Stack>
                        )
                      }
                    >
                      Need an account?
                    </Link>
                  </Stack>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between">
                    <Typography level="body-sm">Need help?</Typography>
                    <Link href="mailto:hello@birthdays.ai" level="body-sm">
                      hello@birthdays.ai
                    </Link>
                  </Stack>
                </Stack>
              </form>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Modal open={!!dialog} onClose={() => setDialog(null)}>
        <ModalDialog>{dialog}</ModalDialog>
      </Modal>
    </>
  );
}
