import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyBHVHs2NTxGVXXXLk1s4AIhICF7e6ctLkw',
  authDomain: 'eventhandler-88.firebaseapp.com',
  databaseURL: 'https://eventhandler-88.firebaseio.com',
  projectId: 'eventhandler-88',
  storageBucket: 'eventhandler-88.appspot.com',
  messagingSenderId: '202312907632',
  appId: '1:202312907632:web:92d0d37844a4a906b6edb5',
  measurementId: 'G-QSSS4352TF'
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);
