import { Button, Typography, Stack, Grid } from '@mui/joy';

const Support = () => {
  return (
    <Grid container justifyContent="center">
      <Grid xs={10} lg={5}>
        <Stack gap={3} mt={3}>
          <Typography level="h1">Support</Typography>
          <Typography level="body-md">
            For all support queries, please email us at
          </Typography>
          <Button
            variant="plain"
            size="lg"
            href="mailto:hi@birthdays.ai"
            component="a"
          >
            hi@birthdays.ai
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Support;
