import { useState } from 'react';
import {
  Input,
  FormControl,
  Select,
  Button,
  Modal,
  Typography,
  ModalDialog,
  FormLabel,
  Option,
  Textarea,
  Stack,
  ModalClose,
  Grid
} from '@mui/joy';
import { DateTime } from 'luxon';

const BirthdayDialog = ({ dialog, handleSave, defaultValues, handleClose }) => {
  const [name, setName] = useState(defaultValues ? defaultValues.name : '');
  const [day, setDay] = useState(defaultValues ? +defaultValues.day : 1);
  const [month, setMonth] = useState(defaultValues ? +defaultValues.month : 1);
  const [year, setYear] = useState(defaultValues ? defaultValues.year : 1980);
  const [notes, setNotes] = useState(defaultValues ? defaultValues.notes : '');
  const [error, setError] = useState();

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const processSave = async e => {
    e.preventDefault();
    const birthday = year
      ? DateTime.fromObject({
          day,
          month,
          year
        })
      : DateTime.fromObject({
          day,
          month
        });
    if (birthday.isValid) {
      handleSave({ name, day, month, year, notes });
      handleClose();
    } else {
      setError('This birthday is not a valid date. Please check and re-save.');
      console.error(birthday.invalidReason, birthday.invalidExplanation);
    }
  };

  return (
    <Modal open={dialog.open} onClose={handleClose}>
      <ModalDialog layout="fullscreen">
        <ModalClose />
        <Grid
          container
          justifyContent="center"
          mt={3}
          overflow="auto"
          maxHeight="100vh"
        >
          <Grid xs={12} lg={6}>
            <Stack gap={2}>
              {error && (
                <Typography level="body-lg" color="danger" variant="soft">
                  {error}
                </Typography>
              )}
              <Typography level="h1">{dialog.title}</Typography>
              <Typography level="body1">{dialog.message}</Typography>
              <form onSubmit={processSave}>
                <Stack spacing={2}>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Day</FormLabel>
                    <Input value={day} onChange={e => setDay(e.target.value)} />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Month</FormLabel>
                    <Select
                      value={month}
                      onChange={(event, newValue) => setMonth(newValue)}
                    >
                      {months.map((month, i) => (
                        <Option value={i + 1} key={i}>
                          {month}
                        </Option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Year</FormLabel>
                    <Input
                      value={year}
                      onChange={e => setYear(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Notes</FormLabel>
                    <Textarea
                      value={notes}
                      onChange={e => setNotes(e.target.value)}
                    />
                  </FormControl>
                  <Button type="submit" disabled={!name || !day || !month}>
                    Save
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Grid>
        </Grid>
        {/* </Stack> */}
      </ModalDialog>
    </Modal>
  );
};

export default BirthdayDialog;
