import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  updateDoc,
  Timestamp
} from 'firebase/firestore';

const AuthAndBirthdayContext = createContext();

// Custom hook to use the context
const useAuthAndBirthday = () => useContext(AuthAndBirthdayContext);

const AuthAndBirthdayProvider = ({ children }) => {
  const [userAuth, setUserAuth] = useState();
  const [user, setUser] = useState();
  const [birthdays, setBirthdays] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(getAuth(), async authUser => {
      if (authUser) {
        setUserAuth(authUser);

        navigate('/dashboard');
      } else {
        setLoading(false);
        setUser(null);
        setBirthdays([]);
        navigate('/');
      }
    });

    return () => unsubscribeAuth();
  }, [navigate]);

  useEffect(() => {
    let unsubscribeUsers, unsubscribeBirthdays;

    if (userAuth) {
      updateDoc(doc(getFirestore(), 'users', userAuth.uid), {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        lastLogin: Timestamp.now()
      });

      unsubscribeUsers = onSnapshot(
        doc(getFirestore(), 'users', userAuth.uid),
        userSnapshot => {
          setUser(userSnapshot.data());
        }
      );

      unsubscribeBirthdays = onSnapshot(
        collection(getFirestore(), 'users', userAuth.uid, 'birthdays'),
        birthdaysSnapshot => {
          const birthdayData = [];
          birthdaysSnapshot.forEach(doc => {
            const birthday = doc.data();
            birthdayData.push({ ...birthday, id: doc.id });
          });
          setBirthdays(birthdayData);
        }
      );
    }

    return () => {
      unsubscribeUsers && unsubscribeUsers();
      unsubscribeBirthdays && unsubscribeBirthdays();
    };
  }, [navigate, userAuth]);

  return (
    <AuthAndBirthdayContext.Provider
      value={{ user, birthdays, loading, userAuth }}
    >
      {children}
    </AuthAndBirthdayContext.Provider>
  );
};

export { useAuthAndBirthday, AuthAndBirthdayProvider };
