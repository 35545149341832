const getBirthstones = month => {
  const birthstones = {
    January: ['Garnet'],
    February: ['Amethyst'],
    March: ['Bloodstone', 'Jasper'],
    April: ['Diamond'],
    May: ['Emerald'],
    June: ['Pearl', 'Moonstone'],
    July: ['Ruby'],
    August: ['Sardonyx', 'Onyx'],
    September: ['Sapphire'],
    October: ['Opal'],
    November: ['Topaz'],
    December: ['Turquoise', 'Lapis Lazuli']
  };

  return birthstones[month];
};

export { getBirthstones };
