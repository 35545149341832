import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
  useLocation
} from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';

import './modules/firebase';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Settings from './components/Settings';
import Birthdays from './components/Birthdays';
import { AuthAndBirthdayProvider } from './context/AuthAndBirthdayContext';
import Support from './components/Support';

const App = () => {
  const location = useLocation();
  const analytics = getAnalytics();

  useEffect(() => {
    console.log(location);
    logEvent(analytics, 'page_view', {
      page_path: location.pathname,
      page_location: window.location.href,
      page_title: document.title,
      referrer: document.referrer
    });
  }, [location, analytics]);

  const theme = extendTheme({
    fontFamily: {
      display: 'Caveat', // applies to `h1`–`h4`
      body: 'Open Sans' // applies to `title-*` and `body-*`
    }
  });
  return (
    <CssVarsProvider defaultMode="system" theme={theme}>
      <CssBaseline />
      <AuthAndBirthdayProvider>
        <Outlet />
      </AuthAndBirthdayProvider>
    </CssVarsProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { index: true, element: <Navigate to="birthdays" replace /> },
          {
            path: 'birthdays',
            element: <Birthdays />
          },
          {
            path: 'settings',
            element: <Settings />
          },
          {
            path: 'support',
            element: <Support />
          }
        ]
      }
    ]
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
