import { useState, useEffect, useCallback } from 'react';
import {
  Button,
  LinearProgress,
  Typography,
  Stack,
  Grid,
  Input,
  Snackbar,
  Checkbox,
  Container,
  Alert,
  Modal,
  ModalDialog,
  Box
} from '@mui/joy';
import { useForm, useWatch } from 'react-hook-form';
import {
  getAuth,
  updateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential,
  signOut
} from 'firebase/auth';
import { doc, getFirestore, updateDoc, arrayUnion } from 'firebase/firestore';
import { getMessaging, getToken } from 'firebase/messaging';
import { WarningRounded } from '@mui/icons-material';

import { useAuthAndBirthday } from '../context/AuthAndBirthdayContext';

const Settings = () => {
  const { user, userAuth } = useAuthAndBirthday();
  const [processing, setProcessing] = useState(false);
  const [deletionCheck, setDeletionCheck] = useState(false);
  const [snackbar, setSnackbar] = useState({ message: '' });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: user.name || '',
      email: userAuth.email || ''
    }
  });

  const watchedName = useWatch({ control, name: 'name' });
  const watchedEmail = useWatch({ control, name: 'email' });

  const setPushNotifications = value =>
    updateDoc(doc(getFirestore(), 'users', userAuth.uid), {
      'birthdayReminders.notifications': value
    });

  const updateToken = useCallback(async () => {
    try {
      const messaging = getMessaging();
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPIDKEY
      });

      if (token) {
        await updateDoc(doc(getFirestore(), 'users', userAuth.uid), {
          fcmTokens: arrayUnion(token)
        });
        console.log('FCM token updated successfully');
      } else {
        console.warn('No registration token available.');
      }
    } catch (error) {
      console.error('Error getting token or updating Firestore:', error);
      throw error;
    }
  }, [userAuth]);

  const handlePushNotificationsChange = async event => {
    if (event.target.checked) {
      if ('Notification' in window) {
        if (Notification.permission !== 'granted') {
          try {
            const permission = await Notification.requestPermission();
            if (permission !== 'granted') {
              setSnackbar({
                message:
                  'Browser permission denied. Push notifications cannot be enabled. Please allow notifications in your browser settings and try again.',
                type: 'danger'
              });
              setPushNotifications(false);
              return;
            }
          } catch (error) {
            console.error('Error requesting notification permission:', error);
            setSnackbar({
              message:
                'Failed to request notification permission. Please try again.',
              type: 'danger'
            });
            setPushNotifications(false);
            return;
          }
        }

        try {
          await updateToken();
          setPushNotifications(true);
          setSnackbar({
            message: 'Push notifications enabled successfully.',
            type: 'success'
          });
        } catch (error) {
          console.error('Error enabling push notifications:', error);
          setSnackbar({
            message: 'Failed to enable push notifications. Please try again.',
            type: 'danger'
          });
          setPushNotifications(false);
        }
      } else {
        setSnackbar({
          message: 'Push notifications are not supported in this browser.',
          type: 'danger'
        });
        setPushNotifications(false);
        return;
      }
    } else {
      setPushNotifications(false);
      setSnackbar({
        message: 'Reminders sent to this device are now disabled.',
        type: 'success'
      });
    }
  };

  const reauthenticateUser = async () => {
    const password = prompt(
      `To update your email, please re-enter your password for ${userAuth.email}:`
    );
    if (password) {
      const credential = EmailAuthProvider.credential(userAuth.email, password);
      await reauthenticateWithCredential(userAuth, credential);
    } else {
      throw new Error('Password not provided');
    }
  };

  useEffect(() => {
    if (user) {
      if ('Notification' in window && Notification.permission === 'granted') {
        updateToken();
      }
    }
  }, [user, updateToken]);

  const onSubmit = async data => {
    setProcessing(true);
    try {
      const auth = getAuth();
      if (data.email && data.email !== userAuth.email) {
        await updateEmail(auth.currentUser, data.email);
      }

      await updateDoc(doc(getFirestore(), 'users', userAuth.uid), {
        name: data.name
      });

      setSnackbar({
        message: 'Profile updated successfully.',
        type: 'success'
      });
    } catch (error) {
      if (error.code === 'auth/requires-recent-login') {
        try {
          await reauthenticateUser();
          await onSubmit(data);
        } catch (reauthError) {
          console.error('Reauthentication failed', reauthError);
          setSnackbar({
            message: 'Reauthentication failed. Please try again.',
            type: 'danger'
          });
        }
      } else {
        console.error(error);
        setSnackbar({
          message: 'There was a problem updating your profile',
          type: 'danger'
        });
      }
    } finally {
      setProcessing(false);
    }
  };

  if (!user) {
    return <LinearProgress />;
  }

  return (
    <Container sx={{ mt: 3, mb: 8 }}>
      <Grid container justifyContent="space-evenly" gap={3}>
        <Grid xs={12}>
          <Typography level="h1">Settings</Typography>
        </Grid>
        <Grid xs={12} md={6} lg={3}>
          <Stack gap={3} mt={3}>
            <Typography level="title-lg">Account Details</Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack gap={2}>
                <Input
                  placeholder="Name"
                  defaultValue={user.name || ''}
                  {...register('name')}
                />
                {errors.name && (
                  <Typography level="body-sm" color="error">
                    Name is required
                  </Typography>
                )}

                <Input
                  placeholder="Email"
                  defaultValue={userAuth.email || ''}
                  {...register('email', { required: true })}
                />
                {errors.email && (
                  <Typography level="body-sm" color="error">
                    Email is required
                  </Typography>
                )}

                <Button
                  type="submit"
                  variant="solid"
                  disabled={
                    watchedName === user.name && watchedEmail === userAuth.email
                  }
                  color="primary"
                  loading={processing}
                >
                  Update Profile
                </Button>
              </Stack>
            </form>
          </Stack>
        </Grid>
        <Grid xs={12} md={6}>
          <Stack gap={3} mt={3}>
            <Typography level="title-lg">
              Birthday Reminder Preferences
            </Typography>
            <Typography level="body-sm">
              Birthday reminders will be sent out 3 weeks before, 1 week before,
              the day before, and on the day of each birthday you have entered.
            </Typography>
            <Stack gap={2}>
              <Checkbox
                label="Receive email reminders"
                checked={user.birthdayReminders?.emails || false}
                onChange={async event => {
                  await updateDoc(doc(getFirestore(), 'users', userAuth.uid), {
                    'birthdayReminders.emails': event.target.checked
                  });
                  setSnackbar({
                    message: `Email reminders ${event.target.checked ? 'enabled!' : 'disabled.'}`,
                    type: 'success'
                  });
                }}
              />
              <Checkbox
                label="Receive reminders to your device"
                checked={user.birthdayReminders.notifications || false}
                onChange={handlePushNotificationsChange}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid xs={9} sm={6} lg={3}>
          <Alert
            color="danger"
            variant="outlined"
            size="sm"
            sx={{ mt: 11 }}
            endDecorator={
              <Button
                size="sm"
                variant="soft"
                color="danger"
                onClick={() => setDeletionCheck(true)}
              >
                delete
              </Button>
            }
          >
            Delete your account?
          </Alert>
        </Grid>
      </Grid>
      <Snackbar
        color={snackbar.type}
        open={!!snackbar.message}
        onClose={() => setSnackbar({ ...snackbar, message: '' })}
        autoHideDuration={snackbar.type === 'success' ? 3333 : 8888}
      >
        {snackbar.message}
      </Snackbar>
      {deletionCheck && (
        <Modal open={deletionCheck} onClose={() => setDeletionCheck(false)}>
          <ModalDialog role="alertdialog">
            <Typography component="h2" startDecorator={<WarningRounded />}>
              Confirmation
            </Typography>
            <Typography
              id="alert-dialog-modal-description"
              textColor="text.tertiary"
            >
              Are you sure you want to delete your account?
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-end',
                pt: 2
              }}
            >
              <Button
                variant="plain"
                color="neutral"
                onClick={() => setDeletionCheck(false)}
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                color="danger"
                onClick={async () => {
                  setDeletionCheck(false);
                  await updateDoc(doc(getFirestore(), 'users', userAuth.uid), {
                    scheduleDeletion: true
                  });
                  signOut(getAuth());
                }}
              >
                Yes, delete
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      )}
    </Container>
  );
};

export default Settings;
