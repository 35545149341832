import { getFunctions, httpsCallable } from 'firebase/functions';

const getCompletion = async content => {
  const createCompletion = httpsCallable(getFunctions(), 'createCompletion');
  const response = await createCompletion({
    messages: [
      {
        role: 'system',
        content: 'You are an expert on suggesting birthday gifts.'
      },
      {
        role: 'user',
        content
      }
    ]
  });

  return response.data.content;
};

export { getCompletion };
