import { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import {
  Menu,
  Settings,
  Cake,
  Logout,
  SupportAgent
} from '@mui/icons-material';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  AspectRatio,
  Typography,
  Button,
  Stack,
  Divider,
  Box
} from '@mui/joy';
import Lottie from 'lottie-react';
import { getAuth, signOut } from 'firebase/auth';
import backgroundImage from '../media/birthdays-candles.png';
import { useAuthAndBirthday } from '../context/AuthAndBirthdayContext';
import loader from '../media/present.json';

const Dashboard = () => {
  const navigate = useNavigate();
  const { user, userAuth } = useAuthAndBirthday();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  if (!user) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Lottie animationData={loader} />
      </Box>
    );
  }

  return (
    <>
      <IconButton
        onClick={toggleDrawer}
        size="lg"
        variant="soft"
        sx={{ position: 'fixed', top: 11, left: 11, zIndex: 1111 }}
      >
        <Menu />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}>
        <AspectRatio ratio="2">
          <img src={backgroundImage} loading="lazy" alt="" />
        </AspectRatio>
        <Stack p={1} display="flex" height="100%">
          <Typography level="h1" mb={1}>
            Birthdays AI
          </Typography>
          <Typography level="body-md" textAlign="right">
            {user.name}
          </Typography>
          <Typography level="body-sm" textAlign="right">
            {userAuth.email}
          </Typography>
          <Divider sx={{ my: 2 }} />
          <List sx={{ flex: 1, '--List-gap': '8px' }} size="lg">
            <ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('birthdays');
                  toggleDrawer();
                }}
              >
                <ListItemDecorator>
                  <Cake />
                </ListItemDecorator>
                <ListItemContent>Birthdays</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('settings');
                  toggleDrawer();
                }}
              >
                <ListItemDecorator>
                  <Settings />
                </ListItemDecorator>
                <ListItemContent>Settings</ListItemContent>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton
                onClick={() => {
                  navigate('support');
                  toggleDrawer();
                }}
              >
                <ListItemDecorator>
                  <SupportAgent />
                </ListItemDecorator>
                <ListItemContent>Support</ListItemContent>
              </ListItemButton>
            </ListItem>
          </List>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography level="body-sm">
              Version {process.env.REACT_APP_VERSION}
            </Typography>
            <Button
              variant="plain"
              onClick={() => signOut(getAuth())}
              startDecorator={<Logout />}
              sx={{ alignSelf: 'end' }}
            >
              Logout
            </Button>
          </Stack>
        </Stack>
      </Drawer>
      <Box p={2} mt={5}>
        <Outlet />
      </Box>
    </>
  );
};

export default Dashboard;
